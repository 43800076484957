import { Main, Heading, MainMaxWidth } from 'ui'

export default function PersonalHome(): JSX.Element {
  return (
    <Main>
      <MainMaxWidth>
        <Heading>Welcome to Tenstorrent Cloud</Heading>
        <ul>
          <li>
            Select or create your team workspace from the top-left dropdown
          </li>
          <li>
            To join an existing team, ask for your team admin to add your
            account through team settings
          </li>
          <li>Find personal resources from the navigation above</li>
        </ul>
      </MainMaxWidth>
    </Main>
  )
}
